import { BlogOverviewCard } from "@/blog/BlogOverview"
import ErrorBoundary from "@/blog/ErrorBoundary"
import { SemiTransparentHeader } from "@/blog/SemiTransparent"
import LoadingComponent from "@/components/Loading"
import { Button } from "@/components/ui/button"
import { H2 } from "@/components/ui/typographys"
import ContactUs from "@/layout/site/ContactUs"
import { gql, useQuery } from "@apollo/client"
import { ArrowBigDownDash, ArrowRight } from "lucide-react"
import React from "react"
import { NavLink, Outlet, Route, Routes, ScrollRestoration, useLocation, useNavigate } from "react-router-dom"


const query = gql`
query AllBlogsQuery($after: ID, $first: Int!) {
  pages(first: $first, after: $after) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        title
        description
        createdAt
        slug
        author
        image
      }
    }
  }
}
`

export function BlogContent({showMorePage = false, limit = 15, heading = "All Blogs"}: {showMorePage?: boolean, limit?: number, heading?: string}) {
  const { data, loading, error, fetchMore } = useQuery(query, {
    variables: {
      first: limit
    }
  })

  function loadMoreBlogs() {
    fetchMore({
      variables: {
        after: data.pages.pageInfo.endCursor
      },
      updateQuery(previousData, { fetchMoreResult, variables: { after }}) {
        // Slicing is necessary because the existing data is
        // immutable, and frozen in development.
        return {
          pages: {
            pageInfo: fetchMoreResult.pages.pageInfo,
            edges: [
              ...previousData.pages.edges,
              ...fetchMoreResult.pages.edges
            ]
          }
        }
      }
    })
  }

  if (loading) {
    return <div className="flex h-screen items-center justify-center">
      <LoadingComponent text="Fetching all the latest blogs"/>
    </div>
  }
  if (error) {
    return <div className="flex h-screen items-center justify-center">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-red-500 mb-4">Error Fetching Blogs</h1>
        <p className="text-lg text-gray-500">An error occurred while fetching the blogs. Please try again later.</p>
      </div>
    </div>
  }
  return <div className="container mx-auto px-4 py-8">
    <H2 className="text-4xl font-bold mb-16 flex justify-between py-6">
      {heading}
      <NavLink to="/blog">
      <Button variant={"link"}>View All <ArrowRight className="ml-4 w-4 h-4"/> </Button>
      </NavLink>
    </H2>
    <div className="grid gap-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
    {
      data.pages.edges.map((edge: any) => {
        return <BlogOverviewCard 
          key={edge.node.id}
          title={edge.node.title}
          excerpt={edge.node.description}
          author={{
            name: edge.node.author,
            avatar: "/placeholder.svg?height=40&width=40"
          }}
          date={new Date(+edge.node.createdAt).toDateString().substring(4)}
          imageUrl={edge.node.image}
          slug={edge.node.slug}
        />
      })
      
    }
    </div>
    {data.pages.pageInfo.hasNextPage && showMorePage && <div className="flex justify-center">
      <Button variant="default" className="mt-8" size={'lg'} onClick={loadMoreBlogs}>Load More <ArrowBigDownDash className="ml-4" /></Button>
    </div>}
  </div>
}

function AllBlogs() {

  return <div>
    <ScrollRestoration />
    <SemiTransparentHeader />
    <ErrorBoundary>
      <React.Suspense fallback={<LoadingComponent text="Loading Blogs" />}>
        <Outlet />
      </React.Suspense>
    </ErrorBoundary>
    <ContactUs />
  </div>
}

export default AllBlogs